import React from 'react'
import { Lang } from '../component/Language/Lang';
import { config } from '../config/rest';
import LoaderComp from './Loader';


export const Aboutus = (props) => {
  return (
    
    <section className="py-0">
       
        {props.abtData?<></>:<LoaderComp/>}
        {props.abtData.map(function(data,index) {
        return (
            <>
            <div className="container-fluid px-0" key={index}>
             
            <div className="row g-0 overlap-column">
                <div className="col-lg-6 bg-img cover-background overflow-visible rounded" data-overlay-dark="0" 
                 style={{ backgroundImage: "url("+config.IMG_URL+data.attributes.abtImage.data.attributes.url+")" }}>
                    <div className="d-flex about-contact">
                        <div className="icon rounded-start">
                            <i className="ti-mobile display-22 text-white d-block mt-2"></i>
                        </div>
                        <div className="contact-text">
                            <h4 className="mb-1 h5 text-white">{Lang[props.langData.lang].homeSecAbtdques}</h4>
                            <span className="text-white fw-bold">{data.attributes.phoneNo}</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="bg-light py-6 py-xl-8 px-1-9 px-xxl-9 rounded">
                        <div className="w-xxl-80">
                            <div className="mb-1-6 mb-lg-1-9 title-style1">
                                <span>{Lang[props.langData.lang].homeSecAbttitle}</span>
                                <h2 className="display-22 display-sm-18 display-md-16 mb-0">
                                    {data.attributes.abtTitle}</h2>
                            </div>
                             <p   lassName="mb-xl-1-9" dangerouslySetInnerHTML={{ __html: data.attributes.aboutText }}/>
                              
                           
                           
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        )})}
   
</section>
  )
}
