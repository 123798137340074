import React from 'react'

export const CeoMessage = (props) => {
  return (//ceoMessData
    <section className="pb-0">
         {props.ceoMessData.map(function(data,index) {
        return (
            <>
    <div className="container" key={index}>
        <div className="row about-style2">
            <div className="col-lg-4 mb-1-9 mb-lg-0 wow fadeIn" data-wow-delay="200ms">
                <div className="pe-xl-1-9 mb-md-2-9 position-relative">
                    <div className="text-end about-img1 position-relative image-hover">
                        <img src="/assets/img/abtus/COO-Photo.jpg" className="rounded" alt="..."/>
                        <p dangerouslySetInnerHTML={{ __html: data.attributes.ceodetails }}/>
                    </div>
                </div>
            </div>
            <div className="col-lg-8 wow fadeIn" data-wow-delay="400ms">
                <div className="ps-xl-2-3">
                    <h2 className="h2 mb-1-6 oragneTxt">Message from COO</h2>
                    <p className="mb-1-9   text-secondary lh34 "
                    dangerouslySetInnerHTML={{ __html: data.attributes.message }}/>
                    
                </div>
            </div>
        </div>
        </div>
        <div className="row about-style2">
        <div className="col-lg-12 wow fadeIn" data-wow-delay="400ms">
       <div className="ps-xl-2-3">
       
         <div className="row about-style2">
        <div className="col-lg-12 wow fadeIn" data-wow-delay="400ms">
       <div className="ps-xl-2-3">
       <div className="inner-title">
                                    <h2 className="h4 mb-0"></h2>
                                </div>

                                <p className="mb-1-9   text-secondary lh34"  />

                               

                                <p className="mb-1-9   text-secondary lh34 "
                                 dangerouslySetInnerHTML={{ __html: data.attributes.messagetwo }}/>

         </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </>
        )})}
        
        </section>
  )
}
