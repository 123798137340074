import { axiosInstance } from "../config/axiosInstance";

export const listHomeSlider = () => axiosInstance.get("/home-sliders?populate=*");
export const listHomeProduct = () => axiosInstance.get("/home-products?populate=*");
export const listHomeAbout = () => axiosInstance.get("/homeabouts?populate=*");
export const listHomeWhychooseUs = () => axiosInstance.get("/homewhy-chooseuses?populate=*");
export const listHomeTestimonial = () => axiosInstance.get("/home-testimonials?populate=*");
export const listHomeNews = () => axiosInstance.get("/homenews?populate=*");
export const listHomecontacts = () => axiosInstance.get("/contacts?populate=*");



export const abtPage = () => axiosInstance.get("/aboutuses?populate=*");
export const ceomessagePage = () => axiosInstance.get("/abt-ceo-messages?populate=*");
export const tenderPage = () => axiosInstance.get("/tenders?populate=*");

export const productPrivatePage = () => axiosInstance.get("/product-privates?populate=*");
export const productcomPage = () => axiosInstance.get("/product-commercials?populate=*");
export const productindPage = () => axiosInstance.get("/product-industrials?populate=*");
export const produlibilityPage = () => axiosInstance.get("/product-liabilities?populate=*");
export const produothersPage = () => axiosInstance.get("/product-others?populate=*");


export const listNewsEventData = () => axiosInstance.get("/newsevents?populate=*");

export const faqPage = () => axiosInstance.get("/faqs?populate=*");
export const faqCeoPage = () => axiosInstance.get("/faq-ceo-details");
export const faqContactPage = () => axiosInstance.get("/faqcontact-details");

export const officeOurOfficePage = () => axiosInstance.get("/our-offices?populate=*");

export const officeTeamWithImage= () => axiosInstance.get("/team-structures?populate[0]=TeamWithImage&populate[1]=TeamWithImage.image");
export const officeTeamStructurePage = () => axiosInstance.get("/team-structures?populate=*");//data[0].attributes.AnnualReport


export const financialAnnualReport= () => axiosInstance.get("/annual-reports?populate=*?populate[0]=AnnualReport&populate[1]=AnnualReport.file");
export const financialAnnualRating= () => axiosInstance.get("/financial-ratings?populate=*");
export const financialPerformance= () => axiosInstance.get("/financial-performances?populate=*");


export const getAdvertisement = () => axiosInstance.get("/popup-messages?populate=*");