import React, { useState } from 'react';
import { config } from '../../config/rest';

let images = [
  {
    src: "/assets/img/gallery/banner1.jpg",
    original: "/assets/img/gallery/banner1.jpg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",

    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  },
  {
    src: "/assets/img/gallery/banner2.jpg",
    original: "/assets/img/gallery/banner2.jpg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  },
  {
    src: "/assets/img/gallery/banner3.jpg",
    original: "/assets/img/gallery/banner3.jpg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  }
];

let images2 = [
  {
    src: "/assets/img/gallery/br1.jpeg",
    original: "/assets/img/gallery/br1.jpeg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",

    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  },
  {
    src: "/assets/img/gallery/br2.jpg",
    original: "/assets/img/gallery/br2.jpg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  },

];

let images3 = [
  {
    src: "/assets/img/gallery/bd1.jpg",
    original: "/assets/img/gallery/bd1.jpg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",

    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  },
  {
    src: "/assets/img/gallery/bd2.jpg",
    original: "/assets/img/gallery/bd2.jpg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  },

];

let images4 = [
  {
    src: "/assets/img/gallery/s1.jpeg",
    original: "/assets/img/gallery/s1.jpeg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",

    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  },
  {
    src: "/assets/img/gallery/s2.jpeg",
    original: "/assets/img/gallery/s2.jpeg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  },
  {
    src: "/assets/img/gallery/s3.jpeg",
    original: "/assets/img/gallery/s3.jpeg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  },
  {
    src: "/assets/img/gallery/s4.jpeg",
    original: "/assets/img/gallery/s4.jpeg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  },
  {
    src: "/assets/img/gallery/s5.jpeg",
    original: "/assets/img/gallery/s5.jpeg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  },
  {
    src: "/assets/img/gallery/s6.jpeg",
    original: "/assets/img/gallery/s6.jpeg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  },
  {
    src: "/assets/img/gallery/s7.jpeg",
    original: "/assets/img/gallery/s7.jpeg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",
    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  },

];

let images5 = [
  {
    src: "/assets/img/gallery/b1.jpeg",
    original: "/assets/img/gallery/b1.jpeg",
    width: 320,
    height: 174,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Flora", title: "Flora" },
    ],
    caption: "After Rain (Jeshu John - designerspics.com)",

    title: "BROKER’S MEET 2023    ",
    description: "BROKER’S MEET 2023    "
  },


];

export const Content = (props) => {
console.log('dfkjdsfkjdfkd',props.newsData);
  return (
    <section>
      <div className="container">
        <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
          <span>Gallery</span>
          <h2 className="h1 mb-0">News & Event</h2>
        </div>
        <div className="row about-style">
          {props.newsData && props.newsData.map(function (data, index) {
            return (
              <div classNameName="col-lg-12 wow fadeIn" data-wow-delay="400ms" key={data.id}>
              <div classNameName="ps-xl-2-3">
                <div className="inner-title whyChoose">
                  <h2 className="h4 mb-0">{data.attributes.title}</h2>
                  <h3 className="h5 mb-3">Date : {data.attributes.date}</h3>
                </div>
              </div>
              <div className="row justify-content-center lightbox-gallery">
                <div className="row photos">
                  {data.attributes.image.data  && data.attributes.image.data.map(function (item, index) {
                    return (
                      <div className="col-sm-6 col-md-4 col-lg-3 item" key={item.id}>
                        <a href={config.IMG_URL+item.attributes.url} data-lightbox="photos">
                          <img className="img-fluid" src={config.IMG_URL+item.attributes.url} /></a></div>
                    )
                  })}
                </div>
  
              </div>
            </div>
                    )

          })}

         


        </div>
      </div>
    </section>
  )
}
