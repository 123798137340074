import React ,{useEffect,useState}from 'react';
import { BreadCumb } from '../component/BreadCumb'
import { Content } from './News/Content'
import { listNewsEventData } from '../service/apiService';

export const News = () => {
  const [newsContact,setnewsContact]=useState([]);
  useEffect(() => {
    getNewsProduct(); 
  },[]);

  const getNewsProduct=async()=>{
    const newsData=await listNewsEventData();
    console.log(newsData);
    setnewsContact(newsData.data.data);
  }

  return (
   <>
     <BreadCumb bgImage={"url('assets/img/banner/newsevents.jpg')"} routeLink={"news"} mainRoute={"Home"} 
    route={"News"} title={"News & Blog"}/>
    <Content newsData={newsContact}/> 
   </>
  )
}
