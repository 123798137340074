import React,{useEffect,useState} from 'react'
import { BreadCumb } from '../component/BreadCumb'
import { Content } from './Faq/Content'
import { faqCeoPage, faqContactPage, faqPage, listHomecontacts } from '../service/apiService'

export const Faq = () => {
  const [faq,setfaq]=useState([]);
  const [faqCeo,setfaqceo]=useState([]);
  const [faqAdd,setfaqadd]=useState([]);
  useEffect(() => {
    getFaq();
  },[]);
  const getFaq=async()=>{
    const faq=await faqPage();
    const ceoDetails=await faqCeoPage();
    const homeContact=await faqContactPage();
    setfaq(faq.data.data);
    setfaqceo(ceoDetails.data.data); 
    setfaqadd(homeContact.data.data);
   
  }
  return (
    <>
     <BreadCumb bgImage={"url('assets/img/banner/faq.jpeg')"} routeLink={"faq"} mainRoute={"Home"} 
    route={"Faq"} title={"FAQ"}/>
    <Content faqData={faq} ceoData={faqCeo} addData={faqAdd}/>
    </>
  )
}
