import React ,{useState,useEffect}from 'react';
import { financialPerformance } from '../../service/apiService';

export const Performance = () => {
  const [financialPerform,setfinancialPerform]=useState([]);
  const [perfomTable,setperformTable]=useState([]);
  useEffect(() => {
      getfinancialPerformance();
     
    },[]);

    const getfinancialPerformance=async()=>{
      const getFinancialperfom=await financialPerformance();
      console.log(getFinancialperfom.data);
       setfinancialPerform(getFinancialperfom.data.data[0].attributes && getFinancialperfom.data.data[0].attributes);
      setperformTable(getFinancialperfom.data.data[0].attributes && getFinancialperfom.data.data[0].attributes.FinancialPerformance);

    } 

  return (
    <section>
    <div className="container">
        <div className="section-heading text-center mb-2-9 mb-lg-6 wow fadeIn" data-wow-delay="100ms">
            <span>{financialPerform.Title}</span>
            <h2 className="h1 mb-0">{financialPerform.Title}</h2>
            
        </div>
        <p className="mb-1-9   text-secondary lh34 ">
{financialPerform.Description}       
         </p>

       <div className="row about-style mb-2-9 mb-lg-6">
                <div classNameName="col-lg-12 wow fadeIn table-responsive" data-wow-delay="400ms">
                <table 
                className='table mb-0'>
<tbody>
<tr>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td colspan="2"><strong>(Rs. in Crores)</strong></td>
</tr>
<tr>
<td width="10%">Year</td>
<td width="14%">Gross Premium<br/>
(in India)</td>
<td width="16%">Gross Premium<br/>
(Outside India)</td>
<td width="15%">Net Premium (Global)</td>
<td width="14%">Net Profit (Global)</td>
<td width="16%">Total Assets (Global)</td>
<td width="15%">Net Worth (Global)</td>
</tr>


{perfomTable.map(function(data,index) {
   return(
<tr key={index}>
<td>{data.year}</td>
<td>{data.GrossPremium}</td>
<td>{data.GrossPremiumOutsideIndia}</td>
<td>{data.NetPremiumGlobal}</td>
<td>{data.NetProfitGlobal}</td> 
<td>{data.TotalAsset}</td>
<td>{data.NetWorth}</td>
</tr>
   )
  })}
</tbody>
</table>
                  </div>
  </div>
        </div>
        </section>
  )
}
