import React from 'react'
import { Lang } from '../component/Language/Lang';
import { config } from '../config/rest';

export const WhyChoose = (props) => {
  return (
    <section>
            <div className="container">
                <div className="text-center mb-2-9 wow fadeIn title-style1" data-wow-delay="100ms">
                    <span>why choose us</span>
                </div>
                <div className="row mt-n2-9">
                
                {props.chooseUs.map(function(data,index) {
                    return(
                        <div key={index} className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="200ms">
                        <div className="d-flex service-style2">
                            <div className="flex-shrink-0">
                                <div className="service-icon">
                                    {data.attributes.icon.data!==null?
                                    <img src={config.IMG_URL+data.attributes.icon.data.attributes.url} alt="..." 
                                    style={{width:'40px',height:'40px'}}/>
                                    :<></>}
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-4 whyChoose">
                                <h3 className="h5 mb-3">{data.attributes.title}</h3>
                                <p className="mb-0 w-xl-90">{data.attributes.details}</p>
                            </div>
                        </div>
                    </div>
                )})}
                   
                   {/* <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="300ms">
                        <div className="d-flex service-style2">
                            <div className="flex-shrink-0">
                                <div className="service-icon">
                                    <img src="assets/img/icons/icon-16.png" alt="..."/>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-4 whyChoose">
                                <h3 className="h5 mb-3">24/7 Awesome Support</h3>
                                <p className="mb-0 w-xl-90">We help you discover any protection inclusions that are ideal for you.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="400ms">
                        <div className="d-flex service-style2">
                            <div className="flex-shrink-0">
                                <div className="service-icon">
                                    <img src="assets/img/icons/icon-17.png" alt="..."/>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-4 whyChoose">
                                <h3 className="h5 mb-3">Save Money</h3>
                                <p className="mb-0 w-xl-90">We help you discover any protection inclusions that are ideal for you.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="500ms">
                        <div className="d-flex service-style2">
                            <div className="flex-shrink-0 ">
                                <div className="service-icon">
                                    <img src="assets/img/icons/icon-18.png" alt="..."/>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-4 whyChoose">
                                <h3 className="h5 mb-3">Flexible Plans</h3>
                                <p className="mb-0 w-xl-90">We help you discover any protection inclusions that are ideal for you.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="600ms">
                        <div className="d-flex service-style2">
                            <div className="flex-shrink-0">
                                <div className="service-icon">
                                    <img src="assets/img/icons/icon-19.png" alt="..."/>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-4 whyChoose">
                                <h3 className="h5 mb-3">Trusted Company</h3>
                                <p className="mb-0 w-xl-90">We help you discover any protection inclusions that are ideal for you.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mt-2-9 wow fadeIn" data-wow-delay="700ms">
                        <div className="d-flex service-style2">
                            <div className="flex-shrink-0">
                                <div className="service-icon">
                                    <img src="assets/img/icons/icon-20.png" alt="..."/>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-4 whyChoose">
                                <h3 className="h5 mb-3">Safe Investment</h3>
                                <p className="mb-0 w-xl-90">We help you discover any protection inclusions that are ideal for you.</p>
                            </div>
                        </div>
            </div>*/}
                </div>
            </div>
        </section>

  )
}
